import { UserProfile } from '@/types/UserProfile'

export interface CompanyProfile {
  name: string
  users: Array<UserProfile & { managerId: string | null }>
  values: {
    id: string
    label: string
  }[]
  organization: {
    id: string
    name: string
    slug: string
    createdAt: string
    updatedAt: string
    publicMetadata: Record<string, unknown>
    privateMetadata: Record<string, unknown>
  }
}

export const companyProfiles: Record<string, CompanyProfile> = {
  default: {
    name: 'Tech Giants',
    organization: {
      id: 'org_mock',
      name: 'Tech Giants',
      slug: 'tech-giants',
      createdAt: '2024-01-11T00:00:00.000Z',
      updatedAt: '2024-01-11T00:00:00.000Z',
      publicMetadata: {},
      privateMetadata: {},
    },
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elon',
        lastName: 'Musk',
        emails: ['emusk@spacex.com'],
        image:
          'https://pbs.twimg.com/profile_images/1590968738358079488/IY9Gx6Ok_400x400.jpg',
        managerId: null,
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Zuckerberg',
        emails: ['mzuckerberg@meta.com'],
        image:
          'https://pbs.twimg.com/profile_images/77846223/profile_400x400.jpg',
        managerId: 'U077TRRJQ3F',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Bezos',
        emails: ['jbezos@blueorigin.com'],
        image:
          'https://pbs.twimg.com/profile_images/1591558315254890500/ETIHb4Nl_400x400.jpg',
        managerId: 'U077TRRJQ3F',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Jobs',
        emails: ['sjobs@apple.com'],
        image:
          'https://pbs.twimg.com/profile_images/1155917668697985025/w4N0eB1E_400x400.jpg',
        managerId: null,
      },
      {
        id: 'U077TRRJQ3K',
        firstName: 'Tim',
        lastName: 'Cook',
        emails: ['tcook@apple.com'],
        image:
          'https://pbs.twimg.com/profile_images/1535420431766671360/Pwq-1eJc_400x400.jpg',
        managerId: 'U077TRRJQ3J',
      },
    ],
    values: [
      { id: '1', label: 'Customer obsession' },
      { id: '2', label: 'Be a hustler' },
      { id: '3', label: 'Ownership' },
      { id: '4', label: 'Focus on quick wins' },
      { id: '5', label: 'Resourcefulness' },
    ],
  },
}
