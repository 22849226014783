import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  addQueryParams,
  deleteResolve,
  fetchResolve,
  patchResolve,
  postResolve,
} from '@/services/api/helpers'
import { createServerErrorToast } from '@/lib/toast'
import { useToast } from '@/hooks/use-toast'

import type { PerformanceCycleSchemaType } from '@/lib/schema/performance-cycle'
import type { OrganizationMembership, UserProfile } from '@/types/UserProfile'
import type { OrganizationSettings } from '@/types/OrganizationSettings'
import type { PerformanceCycle } from '@/types/PerformanceCycle'
import type { AlarmingInsight } from '@/types/AlarmingInsight'

type TeamScope = 'all' | 'directReports' | 'underUser'
export type SupportedLanguage =
  | 'en-us'
  | 'es-es'
  | 'fr-fr'
  | 'de-de'
  | 'it-it'
  | 'pt-pt'
  | null

export const useFetchTeamMembers = (options?: {
  teamScope?: TeamScope
  enabled?: boolean
}) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<UserProfile[]>({
    queryKey: ['useFetchTeamMembers', options?.teamScope, organization?.id],
    queryFn: () =>
      fetchResolve(
        addQueryParams(`/organization/${organization?.id}/user`, {
          teamScope: options?.teamScope || 'all',
        }),
        getToken,
      ),
    enabled: options?.enabled,
    staleTime: 30000,
  })
}

export const useFetchOrganizationMemberships = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<OrganizationMembership[]>({
    queryKey: ['useFetchOrganizationMemberships', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/membership`, getToken),
  })
}

export const useFetchOrganizationMembershipByUserId = (userId: string) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<OrganizationMembership>({
    queryKey: [
      'useFetchOrganizationMembershipByUserId',
      organization?.id,
      userId,
    ],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/user/${userId}/membership`,
        getToken,
      ),
  })
}

export const useFetchIntegrations = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<{ type: string; enabled: boolean }[]>({
    queryKey: ['useFetchIntegrations', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/integration`, getToken),
  })
}

export const usePatchOrganizationMembership = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      membershipId: string
      // TODO: Add zod schema here
      body: {
        managerId?: string | null
        isLicensed?: boolean
        preferredLanguage?: SupportedLanguage
      }
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/membership/${props.membershipId}`,
        props.body,
        getToken,
      ),
    onSuccess: (newMembership: OrganizationMembership, variables) => {
      queryClient.setQueryData(
        ['useFetchOrganizationMemberships', organization?.id],
        (old: OrganizationMembership[]) =>
          old.map((node) =>
            node.id === variables.membershipId ? newMembership : node,
          ),
      )
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchOrganizationMemberships', organization?.id],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchOrganizationMemberships',
        organization?.id,
      ])

      queryClient.setQueryData(
        ['useFetchOrganizationMemberships', organization?.id],
        (old: OrganizationMembership[]) =>
          old?.map((membership) =>
            membership.id === variables.membershipId
              ? {
                  ...membership,
                  ...variables.body,
                }
              : membership,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchOrganizationMemberships', organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchLicenseInfo', organization?.id],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchOrganizationMembershipByUserId', organization?.id],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamMembers', organization?.id],
      })
    },
  })
}

export const usePatchOrganizationSettings = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (body: { autoLicenseEnabled?: boolean }) =>
      await patchResolve(
        `/organization/${organization?.id}/settings`,
        body,
        getToken,
      ),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchOrganizationSettings', organization?.id],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchOrganizationSettings',
        organization?.id,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchOrganizationSettings', organization?.id],
        (old: OrganizationSettings[]) => ({ ...old, ...variables }),
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchOrganizationSettings', organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchOrganizationSettings', organization?.id],
      })
    },
  })
}

export const useFetchOrganizationSettings = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<OrganizationSettings>({
    queryKey: ['useFetchOrganizationSettings', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/settings`, getToken),
  })
}

export const useFetchOrganizationPerformanceCycles = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<PerformanceCycle[]>({
    queryKey: ['useFetchOrganizationPerformanceCycles', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/performance-cycle`,
        getToken,
      ),
  })
}

export const usePatchOrganizationPerformanceCycle = (id: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (data: PerformanceCycleSchemaType) =>
      patchResolve(
        `/organization/${organization?.id}/performance-cycle/${id}`,
        data,
        getToken,
      ),
    onSuccess: (data: PerformanceCycle) => {
      queryClient.setQueryData(
        ['useFetchOrganizationPerformanceCycles', organization?.id],
        (old: PerformanceCycle[]) =>
          old.map((elem) => (elem.id === data.id ? data : elem)),
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useCreateOrganizationPerformanceCycle = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (data: PerformanceCycleSchemaType) =>
      postResolve(
        `/organization/${organization?.id}/performance-cycle`,
        data,
        getToken,
      ),
    onSuccess: (data: PerformanceCycle) => {
      queryClient.setQueryData(
        ['useFetchOrganizationPerformanceCycles', organization?.id],
        (old: PerformanceCycle[]) => [...old, data],
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useDeleteOrganizationPerformanceCycle = (id: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async () =>
      deleteResolve(
        `/organization/${organization?.id}/performance-cycle/${id}`,
        getToken,
      ),
    onSuccess: () => {
      queryClient.setQueryData(
        ['useFetchOrganizationPerformanceCycles', organization?.id],
        (old: PerformanceCycle[]) => old.filter((elem) => elem.id !== id),
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useFetchAlarmingInsights = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<AlarmingInsight[]>({
    queryKey: ['useFetchAlarmingInsights', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/alarming-insights`,
        getToken,
      ),
  })
}
