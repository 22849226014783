import { useRef, useState } from 'react'
import moment from 'moment'
import { CheckCircle, ExternalLink, MinusCircle, XCircle } from 'lucide-react'
import { useReactToPrint } from 'react-to-print'

import { Loading } from '@/components/ui/loading'
import { H4, H5, Muted, P, Small } from '@/components/ui/typography'
import { AvatarWithFallback } from '@/components/ui/avatar'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { Progress } from '@/components/ui/progress'
import { TooltipWithIcon } from '@/components/common/tooltip'
import { LayoutTile } from '@/components/layout'
import { Button } from '@/components/ui/button'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutHeader } from '@/components/common/layout-header'

import {
  useDeleteFeedbackPoint,
  useFetchFeedbackPoints,
} from '@/services/api/feedbackPoints.api'
import {
  getUserAvatarFallback,
  getUserDisplayName,
} from '@/services/utils/formatters'

import { FeedbackPoint } from '@/types/FeedbackPoint'

const SHORT_DESCRIPTION_PLACEHOLDER =
  'Summary not available. Please review the detailed feedback for more information.'

const checklistTooltips = {
  'Target individual':
    'Indicates whether the feedback is focused on the correct individual and is personalized for them.',
  'Clear description of the event':
    'Evaluates whether the feedback clearly describes the specific event or behavior that triggered the feedback.',
  'Examples to support the points':
    'Assesses if relevant examples were provided to substantiate the points raised in the feedback.',
  'Suggestions for improvement':
    'Looks at whether constructive suggestions were provided to help the individual improve or address the feedback.',
  'Impact of behavior':
    "Clarifies if the feedback outlines how the individual's behavior influenced the team, project, or environment.",
}

const sentimentTooltips = {
  Care: "Reflects the colleague's demonstrated attentiveness and consideration for others during their work.",
  Confidence:
    'Shows how assured and decisive the colleague appeared in their actions and decisions.',
  Enthusiasm:
    'Indicates the level of energy, passion, and interest the colleague brought to their tasks or the team.',
  Satisfaction:
    'Measures how pleased or content the colleague seemed with their own performance or the outcomes of their efforts.',
  Constructiveness:
    "Assesses the colleague's ability to offer helpful feedback or solutions to others in a positive and productive manner.",
}

const FeedbackPointItem = ({
  feedbackPoint,
}: {
  feedbackPoint: FeedbackPoint
}) => {
  const { mutate: deleteFeedbackPoint } = useDeleteFeedbackPoint()
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleDeleteClick = () => {
    deleteFeedbackPoint(
      { id: feedbackPoint.id },
      {
        onSuccess: () => setIsOpen(false),
      },
    )
  }

  const handleEditClick = () => {}

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: `Feedback Point for ${getUserDisplayName(feedbackPoint.userTo)}`,
    pageStyle: `
      @media print {
        @page {
          margin: 20mm;
        }
      }
    `,
  })

  return (
    <>
      <div
        className="flex gap-4 items-center justify-between px-2 py-4 border-b cursor-pointer hover:bg-gray-50"
        onClick={handleClick}
      >
        <AvatarWithFallback
          className="h-12 w-12 border-2 border-primary flex-shrink-0"
          image={feedbackPoint.userTo.image}
          fallback={getUserAvatarFallback(feedbackPoint.userTo)}
        />
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <H5>{getUserDisplayName(feedbackPoint.userTo)}</H5>
            <time className="text-sm text-muted-foreground">
              {moment(feedbackPoint.created_at).fromNow()}
            </time>
          </div>
          <div className="flex justify-between items-center">
            <p className="line-clamp-1 text-sm text-muted-foreground flex-grow">
              {feedbackPoint.short_description || SHORT_DESCRIPTION_PLACEHOLDER}
            </p>
            <Small className="text-primary underline ml-2 flex-shrink-0">
              Read more
            </Small>
          </div>
        </div>
      </div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          className="sm:max-w-[725px] max-h-[80vh] overflow-y-auto"
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
          onPrint={handlePrint}
          noInnerScroll
          showBottomCloseButton
          customCloseButton={
            <Button className="w-full" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          }
        >
          <div ref={contentRef} className="flex flex-col gap-6">
            <div className="flex items-center gap-4">
              <AvatarWithFallback
                className="w-12 h-12"
                image={feedbackPoint.userTo.image}
                fallback={getUserAvatarFallback(feedbackPoint.userTo)}
              />
              <div>
                <P className="font-semibold">
                  {getUserDisplayName(feedbackPoint.userTo)}
                </P>
                <Muted>{moment(feedbackPoint.created_at).format('ll')}</Muted>
              </div>
            </div>
            <div className="grid gap-6">
              <div className="flex flex-wrap gap-6">
                {!!feedbackPoint.checklist?.length && (
                  <div className="flex-1 min-w-[230px]">
                    <h3 className="mb-4 text-lg font-medium">
                      Quality checklist
                    </h3>
                    <div className="grid gap-4">
                      {feedbackPoint.checklist.map((item, index) => (
                        <div key={index} className="flex items-center gap-2">
                          {item.status === 'Yes' && (
                            <CheckCircle className="w-5 h-5 text-green-500" />
                          )}
                          {item.status === 'Partial' && (
                            <MinusCircle className="w-5 h-5 text-yellow-500" />
                          )}
                          {item.status === 'No' && (
                            <XCircle className="w-5 h-5 text-red-500" />
                          )}
                          <P>{item.label}</P>
                          <TooltipWithIcon
                            type="info"
                            content={
                              checklistTooltips[
                                item.label as keyof typeof checklistTooltips
                              ] || 'No description available.'
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!!Object.entries(feedbackPoint.sentiment_analysis ?? {})
                  .length && (
                  <div className="flex-1 min-w-[230px]">
                    <h3 className="mb-4 text-lg font-medium">
                      {`Values shown by ${getUserDisplayName(feedbackPoint.userTo)}`}
                    </h3>
                    <div className="grid gap-2">
                      {feedbackPoint.sentiment_analysis &&
                        Object.entries(feedbackPoint.sentiment_analysis).map(
                          ([key, value]) => (
                            <div key={key}>
                              <div className="flex items-center gap-2">
                                <P className="capitalize">{key}</P>
                                <TooltipWithIcon
                                  type="info"
                                  content={
                                    sentimentTooltips[
                                      key as keyof typeof sentimentTooltips
                                    ] || 'No description available.'
                                  }
                                />
                              </div>
                              <Progress
                                value={value * 20}
                                aria-label={`${key} score: ${value}`}
                              />
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                )}
              </div>
              {feedbackPoint.detailed_description && (
                <div>
                  <H4>Feedback Summary</H4>
                  <P className="mt-1">{feedbackPoint.detailed_description}</P>
                </div>
              )}
              <div>
                <H4>Full Feedback</H4>
                <P className="mt-1">
                  {feedbackPoint.messages
                    .map(({ message }) => message)
                    .join(' ')}
                </P>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export const FeedbackPointsPage = () => {
  const {
    data: feedbackPoints = [],
    isLoading,
    error,
  } = useFetchFeedbackPoints()

  if (error) return <ErrorTile message={error.message} />

  return (
    <LayoutTile>
      <LayoutHeader
        title="Your Feedback Points"
        description={
          <>
            Find all the feedback moments you had with your colleagues.{' '}
            <a
              href="https://youtu.be/fnDPLe6HLsU"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <ExternalLink size={16} className="ml-1" />
            </a>
          </>
        }
        rightSide={
          <Button
            className="give-feedback-button"
            onClick={() =>
              window.open(
                'slack://app?id=A074N837FB9',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            Give feedback to Fiddy
          </Button>
        }
      />
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex feedback-points-container">
          <Loading />
        </div>
      ) : !feedbackPoints.length ? (
        <div className="flex-1 w-full justify-center items-center flex feedback-points-container">
          <Muted>No Feedback Points Available</Muted>
        </div>
      ) : (
        <div className="feedback-points-container">
          {feedbackPoints.map((fp) => (
            <FeedbackPointItem key={fp.id} feedbackPoint={fp} />
          ))}
        </div>
      )}
    </LayoutTile>
  )
}
