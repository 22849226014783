import React, { useMemo } from 'react'
import moment from 'moment'
import colors from 'tailwindcss/colors'
import { DateRange } from 'react-day-picker'
import { useUser } from '@clerk/clerk-react'

import { Tile } from '@/components/common/tile'
import { Muted, H4 } from '@/components/ui/typography'
import { TooltipWithIcon } from '@/components/common/tooltip'
import BarChart from '@/components/common/bar-chart'
import { PlayCircle } from 'lucide-react'
import { Table } from '@/components/common/table'
import LineChart from '@/components/common/line-chart'

import { useScreenDetector } from '@/hooks/useScreenDetector'
import { formatDecimalToPercentage } from '@/services/utils/formatters'
import { getUserDisplayName } from '@/services/utils/formatters'

import { FeedbackStats } from '@/types/FeedbackStats'
import { Progress } from '@/components/ui/progress'
import { UserSentimentStats } from '@/types/UserSentimentStats'
import { useFetchAlarmingInsights } from '@/services/api/organization.api'

const MINIMUM_FEEDBACK_COUNT = 5

interface MetricTileProps {
  title: string
  value: string | number
  isLoading: boolean
  valueColor?: string
  tooltipContent: string
  tooltipType?: 'info' | 'warning' | 'error'
}

export const MetricTile: React.FC<MetricTileProps> = ({
  title,
  value,
  isLoading,
  valueColor = 'text-primary',
  tooltipContent,
  tooltipType = 'info',
}) => (
  <Tile className="min-h-20" isLoading={isLoading}>
    <div className="flex items-center gap-2">
      <Muted>{title}</Muted>
      <TooltipWithIcon content={tooltipContent} type={tooltipType} />
    </div>
    <H4 className={valueColor}>{value}</H4>
  </Tile>
)

interface DashboardContentProps {
  feedbackStats?: FeedbackStats
  userSentimentStats?: UserSentimentStats[]
  isLoadingStats: boolean
  viewType: 'personal' | 'management'
  dateRange: DateRange
}

const getSeverityLevel = (score: number) => {
  if (score <= 2) return { level: 'Low', color: 'bg-green-50' }
  if (score <= 3) return { level: 'Medium', color: 'bg-orange-50' }
  return { level: 'High', color: 'bg-red-50' }
}

const BetaBadge: React.FC = () => (
  <span className="ml-2 px-1.5 py-0.5 text-[10px] font-medium bg-primary/10 text-primary rounded">
    BETA
  </span>
)

interface PulseTableProps {
  viewType: 'personal' | 'management'
  userId?: string
}

const PulseTable = ({ viewType, userId }: PulseTableProps) => {
  const { data: alarmingInsights = [], isLoading } = useFetchAlarmingInsights()

  const filteredInsights =
    viewType === 'management'
      ? alarmingInsights
      : alarmingInsights.filter((point) => point.userFrom.id === userId)

  return (
    <Tile className="h-[300px]" isLoading={isLoading}>
      <div className="flex items-center gap-2 mb-1">
        <Muted>
          {viewType === 'management' ? 'Team pulse' : 'My pulse'}
          <BetaBadge />
        </Muted>
        <TooltipWithIcon
          content={
            viewType === 'management'
              ? "Covers the most concerning feedback points based on Fiddy's analysis"
              : "Shows concerning feedback points you've given to others"
          }
          type="info"
        />
      </div>
      {filteredInsights.length > 0 ? (
        <Table
          data={filteredInsights}
          columns={[
            {
              header: 'User',
              accessor: (row) => getUserDisplayName(row.userTo),
            },
            {
              header: 'Severity',
              accessor: (row) => getSeverityLevel(row.alarmingScore ?? 0).level,
            },
            {
              header: 'Date',
              accessor: (row) => moment(row.created_at).format('MMM D, YYYY'),
              className: 'text-right',
            },
            {
              header: 'Reason',
              accessor: (row) => row.alarmingReasoning,
            },
          ]}
          rowClassName={(row) => getSeverityLevel(row.alarmingScore ?? 0).color}
        />
      ) : (
        <div className="flex flex-1 justify-center items-center">
          <Muted>No concerning feedback points found</Muted>
        </div>
      )}
    </Tile>
  )
}

export const DashboardContent: React.FC<DashboardContentProps> = ({
  feedbackStats,
  userSentimentStats,
  isLoadingStats,
  viewType,
  dateRange,
}) => {
  const { user } = useUser()
  const { isMobile } = useScreenDetector()
  const dateIntervalsToShow = useMemo(() => {
    if (!dateRange.from || !dateRange.to) return []

    const intervals = []
    const current = moment(dateRange.from).startOf('week')
    const end = moment(dateRange.to).endOf('week')

    while (current.isSameOrBefore(end)) {
      intervals.push(current.toDate())
      current.add(1, 'week')
    }

    return isMobile ? intervals.slice(-4) : intervals
  }, [dateRange, isMobile])

  const checklistComplianceValue = useMemo(() => {
    return feedbackStats?.checklistCompliance ?? 0
  }, [feedbackStats])

  const companyValues = useMemo(() => {
    if (!feedbackStats?.valuesAverage) return []
    return Object.entries(feedbackStats.valuesAverage).map(([name, score]) => ({
      name,
      score,
    }))
  }, [feedbackStats?.valuesAverage])

  const sortedCompanyValues = useMemo(() => {
    return [...companyValues].sort((a, b) => a.name.localeCompare(b.name))
  }, [companyValues])

  const sortedByChurnRisk = useMemo(() => {
    if (!userSentimentStats) return { top: [], bottom: [] }

    const validUsers = userSentimentStats.filter(
      (stat) =>
        stat.feedbackCount >= MINIMUM_FEEDBACK_COUNT &&
        (stat.churnRiskDev ?? 0) > 0, // Only positive deviations
    )

    const sorted = [...validUsers].sort(
      (a, b) => (b.churnRiskDev ?? 0) - (a.churnRiskDev ?? 0),
    )

    return {
      top: sorted.slice(0, 3), // Top 3 highest positive deviations
      bottom: [], // No bottom cases needed for churn risk
    }
  }, [userSentimentStats])

  const sortedByPerformance = useMemo(() => {
    if (!userSentimentStats) return { top: [], bottom: [] }

    const validUsers = userSentimentStats.filter(
      (stat) => stat.feedbackCount >= MINIMUM_FEEDBACK_COUNT,
    )

    const positiveUsers = validUsers
      .filter((stat) => (stat.topPerformerDev ?? 0) > 0)
      .sort((a, b) => (b.topPerformerAvg ?? 0) - (a.topPerformerAvg ?? 0))
      .slice(0, 3)

    const negativeUsers = validUsers
      .filter((stat) => (stat.topPerformerDev ?? 0) < 0)
      .sort((a, b) => (b.topPerformerAvg ?? 0) - (a.topPerformerAvg ?? 0))
      .slice(-3)

    return {
      top: positiveUsers,
      bottom: negativeUsers,
    }
  }, [userSentimentStats])

  const sortedByValues = useMemo(() => {
    if (!userSentimentStats) return { top: [], bottom: [] }

    const usersWithAverageDeviation = userSentimentStats
      .filter(
        (stat) =>
          stat.feedbackCount >= MINIMUM_FEEDBACK_COUNT && stat.valueScoresDev,
      )
      .map((stat) => {
        const deviations = Object.values(stat.valueScoresDev ?? {})
        const avgDeviation = deviations.length
          ? deviations.reduce((sum, dev) => sum + dev, 0) / deviations.length
          : 0

        const valueAvg =
          Object.values(stat.valueScoresAvg ?? {}).reduce(
            (sum, score) => sum + score,
            0,
          ) / Object.values(stat.valueScoresAvg ?? {}).length

        return { ...stat, avgValueDeviation: avgDeviation, valueAvg }
      })

    const positiveUsers = usersWithAverageDeviation
      .filter((stat) => stat.avgValueDeviation > 0)
      .sort((a, b) => b.valueAvg - a.valueAvg)
      .slice(0, 3)

    const negativeUsers = usersWithAverageDeviation
      .filter((stat) => stat.avgValueDeviation < 0)
      .sort((a, b) => b.valueAvg - a.valueAvg)
      .slice(-3)

    return {
      top: positiveUsers,
      bottom: negativeUsers,
    }
  }, [userSentimentStats])

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <MetricTile
          title="Feedback Points"
          value={
            (feedbackStats?.done?.meets_checklist ?? 0) +
            (feedbackStats?.done?.does_not_meet_checklist ?? 0)
          }
          isLoading={isLoadingStats}
          tooltipContent="Number of Feedback Points within the selected time period"
        />
        <MetricTile
          title="Missed Feedback"
          value={formatDecimalToPercentage(
            feedbackStats?.missedProactiveFeedback ?? 0,
            0,
          )}
          isLoading={isLoadingStats}
          valueColor={
            (feedbackStats?.missedProactiveFeedback ?? 0) > 0.3
              ? 'text-rose-400'
              : 'text-green-600'
          }
          tooltipContent="Percentage of missed feedback requests from Fiddy"
          tooltipType="info"
        />
        <MetricTile
          title="Quality score"
          value={formatDecimalToPercentage(checklistComplianceValue, 0)}
          isLoading={isLoadingStats}
          valueColor={
            checklistComplianceValue < 0.7 ? 'text-rose-400' : 'text-green-600'
          }
          tooltipContent="Percentage of quality checklist criteria that are met"
          tooltipType="info"
        />
        <Tile
          className="min-h-20 flex flex-col justify-between"
          onClick={() =>
            window.open(
              'https://www.youtube.com/watch?v=1GzBSuNJdHA&list=PLc5u-ik0Rm5ekzrCpESsanA4zD9kAaV55&index=1',
              '_blank',
            )
          }
        >
          <div className="flex items-center gap-2">
            <Muted>Questions on how to use?</Muted>
          </div>
          <div className="flex items-center gap-2">
            <PlayCircle className="w-5 h-5 text-primary" />
            <span className="font-medium">Check our tutorials</span>
          </div>
        </Tile>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        <Tile className="lg:col-span-3 h-[270px]" isLoading={isLoadingStats}>
          <div>
            <Muted>Feedback Points over time</Muted>
          </div>
          <BarChart
            data={dateIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )
              return {
                label: `W${moment(week).week()}`,
                meetsChecklist: weekDetails?.done.meets_checklist ?? 0,
                doesNotMeetChecklist:
                  weekDetails?.done.does_not_meet_checklist ?? 0,
                qualityScore: weekDetails?.checklistScores ?? 0,
              }
            })}
            bars={['meetsChecklist', 'doesNotMeetChecklist']}
            config={{
              meetsChecklist: {
                label: 'Meets quality threshold',
                color: colors.green['600'],
              },
              doesNotMeetChecklist: {
                label: "Doesn't meet quality threshold",
                color: colors.red['200'],
              },
              // TODO: Add quality score in secondary axis
              qualityScore: {
                label: 'Quality Score',
                color: colors.blue['600'],
              },
            }}
            activeIndex={dateIntervalsToShow.length}
            stacked={true}
          />
        </Tile>
        <Tile className="lg:col-span-2 h-[270px]" isLoading={isLoadingStats}>
          <div className="flex items-center gap-2">
            <Muted>Average values</Muted>
            <TooltipWithIcon
              content={
                viewType === 'personal'
                  ? 'The average score others have given you (if more than 5 feedback points)'
                  : 'The average scores attributed to the selected team members (if more than 5 feedback points)'
              }
            />
          </div>
          <div className="flex flex-1 flex-col gap-4 justify-center">
            {sortedCompanyValues.length > 0 ? (
              sortedCompanyValues.map((value) => (
                <div
                  key={value.name}
                  className="flex items-center justify-between w-full"
                >
                  <span
                    className="w-32 text-sm text-muted-foreground mr-2 truncate font-medium"
                    title={value.name}
                  >
                    {value.name}
                  </span>
                  <div className="flex-1 mx-2">
                    <Progress value={value.score * 20} className="h-4" />
                  </div>
                  <span className="text-sm font-medium">
                    {value.score.toFixed(1)}
                  </span>
                </div>
              ))
            ) : (
              <div className="text-center text-muted-foreground">
                {viewType === 'personal'
                  ? 'You need at least 5 complete feedback points from others to see your average values'
                  : 'No values data available for the selected team members'}
              </div>
            )}
          </div>
        </Tile>
      </div>

      {viewType === 'management' ? (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <Tile className="h-[300px]" isLoading={isLoadingStats}>
              <div className="flex items-center gap-2">
                <Muted>
                  Employees at risk
                  <BetaBadge />
                </Muted>
                <TooltipWithIcon
                  content="Shows employees with highest deviation from average churn risk. The action should be taken by the manager."
                  type="info"
                />
              </div>
              <Table
                data={[
                  ...sortedByChurnRisk.top,
                  ...(sortedByChurnRisk.top.length > 0 &&
                  sortedByChurnRisk.bottom.length > 0
                    ? [{ userId: 'separator' } as UserSentimentStats]
                    : []),
                  ...sortedByChurnRisk.bottom,
                ]}
                columns={[
                  {
                    header: 'Employee',
                    accessor: (row) =>
                      row.userId === 'separator' ? (
                        <div className="text-center text-muted-foreground">
                          •••
                        </div>
                      ) : (
                        getUserDisplayName(row.user!)
                      ),
                  },
                  {
                    header: 'Score',
                    accessor: (row) =>
                      row.userId === 'separator'
                        ? ''
                        : (row.churnRiskAvg ?? 0).toFixed(2),
                    className: 'text-right',
                  },
                  {
                    header: 'Deviation',
                    accessor: (row) =>
                      row.userId === 'separator'
                        ? ''
                        : `${(row.churnRiskDev ?? 0) > 0 ? '+' : ''}${(
                            (row.churnRiskDev ?? 0) * 100
                          ).toFixed(0)}%`,
                    className: 'text-right',
                  },
                ]}
                rowClassName={(row) =>
                  row.userId === 'separator'
                    ? ''
                    : sortedByChurnRisk.top.some((s) => s.userId === row.userId)
                      ? 'text-amber-500'
                      : 'text-green-500'
                }
              />
            </Tile>

            <Tile className="h-[300px]" isLoading={isLoadingStats}>
              <div className="flex items-center gap-2">
                <Muted>
                  Perceived top performers
                  <BetaBadge />
                </Muted>
                <TooltipWithIcon
                  content="Employees with highest deviation from average perceived performance. Perceived performance is based on the feedback points one received from others, not their actual performance."
                  type="info"
                />
              </div>
              <Table
                data={[
                  ...sortedByPerformance.top,
                  ...(sortedByPerformance.top.length > 0 &&
                  sortedByPerformance.bottom.length > 0
                    ? [{ userId: 'separator' } as UserSentimentStats]
                    : []),
                  ...sortedByPerformance.bottom,
                ]}
                columns={[
                  {
                    header: 'Employee',
                    accessor: (row) =>
                      row.userId === 'separator' ? (
                        <div className="text-center text-muted-foreground">
                          •••
                        </div>
                      ) : (
                        getUserDisplayName(row.user!)
                      ),
                  },
                  {
                    header: 'Score',
                    accessor: (row) =>
                      row.userId === 'separator'
                        ? ''
                        : (row.topPerformerAvg ?? 0).toFixed(2),
                    className: 'text-right',
                  },
                  {
                    header: 'Deviation',
                    accessor: (row) =>
                      row.userId === 'separator'
                        ? ''
                        : `${(row.topPerformerDev ?? 0) > 0 ? '+' : ''}${(
                            (row.topPerformerDev ?? 0) * 100
                          ).toFixed(0)}%`,
                    className: 'text-right',
                  },
                ]}
                rowClassName={(row) =>
                  row.userId === 'separator'
                    ? ''
                    : sortedByPerformance.top.some(
                          (s) => s.userId === row.userId,
                        )
                      ? 'text-green-500'
                      : 'text-amber-500'
                }
              />
            </Tile>

            <Tile className="h-[300px]" isLoading={isLoadingStats}>
              <div className="flex items-center gap-2">
                <Muted>
                  Values deviation
                  <BetaBadge />
                </Muted>
                <TooltipWithIcon
                  content="Shows employees with highest deviation from average values scores"
                  type="info"
                />
              </div>
              <Table
                data={[
                  ...sortedByValues.top,
                  ...(sortedByValues.top.length > 0 &&
                  sortedByValues.bottom.length > 0
                    ? [{ userId: 'separator' } as UserSentimentStats]
                    : []),
                  ...sortedByValues.bottom,
                ]}
                columns={[
                  {
                    header: 'Employee',
                    accessor: (row) =>
                      row.userId === 'separator' ? (
                        <div className="text-center text-muted-foreground">
                          •••
                        </div>
                      ) : (
                        getUserDisplayName(row.user!)
                      ),
                  },
                  {
                    header: 'Score',
                    accessor: (row) =>
                      row.userId === 'separator'
                        ? ''
                        : (
                            Object.values(row.valueScoresAvg ?? {}).reduce(
                              (a, b) => a + b,
                              0,
                            ) / Object.values(row.valueScoresAvg ?? {}).length
                          ).toFixed(2),
                    className: 'text-right',
                  },
                  {
                    header: 'Deviation',
                    accessor: (row) =>
                      row.userId === 'separator'
                        ? ''
                        : `${
                            Object.values(row.valueScoresDev ?? {}).reduce(
                              (a, b) => a + b,
                              0,
                            ) /
                              Object.values(row.valueScoresDev ?? {}).length >
                            0
                              ? '+'
                              : ''
                          }${(
                            (Object.values(row.valueScoresDev ?? {}).reduce(
                              (a, b) => a + b,
                              0,
                            ) /
                              Object.values(row.valueScoresDev ?? {}).length) *
                            100
                          ).toFixed(0)}%`,
                    className: 'text-right',
                  },
                ]}
                rowClassName={(row) =>
                  row.userId === 'separator'
                    ? ''
                    : sortedByValues.top.some((s) => s.userId === row.userId)
                      ? 'text-green-500'
                      : 'text-amber-500'
                }
              />
            </Tile>
          </div>
          <div className="col-span-full">
            <PulseTable viewType={viewType} />
          </div>
        </>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
          <div className="lg:col-span-3">
            <PulseTable viewType={viewType} userId={user?.id} />
          </div>
          <div className="lg:col-span-2">
            <Tile className="h-[300px]" isLoading={isLoadingStats}>
              <Muted>Quality score over time</Muted>
              <LineChart
                data={dateIntervalsToShow.map((week) => {
                  const weekDetails = feedbackStats?.weekDetails.find(
                    (elem) => elem.week === moment(week).week(),
                  )

                  const totalScores = weekDetails?.checklistScores?.reduce(
                    (acc, score) => {
                      if (
                        typeof score.yes === 'number' &&
                        typeof score.no === 'number' &&
                        typeof score.partial === 'number'
                      ) {
                        return {
                          yes: acc.yes + score.yes,
                          no: acc.no + score.no,
                          partial: acc.partial + score.partial,
                        }
                      }
                      return acc
                    },
                    { yes: 0, no: 0, partial: 0 },
                  ) ?? { yes: 0, no: 0, partial: 0 }

                  const total =
                    totalScores.yes + totalScores.no + totalScores.partial
                  const complianceScore =
                    total > 0
                      ? Number(
                          (
                            (totalScores.yes + totalScores.partial * 0.5) /
                            total
                          ).toFixed(2),
                        )
                      : null

                  return {
                    label: `W${moment(week).week()}`,
                    averageCompliance: complianceScore,
                  }
                })}
                lines={['averageCompliance']}
                config={{
                  averageCompliance: {
                    label: 'Average Quality Score',
                    color: colors.blue['600'],
                  },
                }}
                domain={[0, 1]}
                type="monotone"
                referenceLine={{
                  y: 0.7,
                  stroke: colors.gray['400'],
                  strokeDasharray: '3 3',
                  label: { value: 'Target: 0.7', position: 'insideTopRight' },
                }}
                storageKey="dashboard.quality-score-chart.hidden-lines"
              />
            </Tile>
          </div>
        </div>
      )}
    </>
  )
}
